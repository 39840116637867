import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { HttpClientOptionsService } from '../../authentication/services/http-client-options.service'
import { map } from 'rxjs/operators'
import {
    AccountRequest,
    MerchantRequest,
} from '../../features/new-affiliate-psp/models/platform-accreditation-models'
import { BehaviorSubject } from 'rxjs'

interface GetRelatedPartnerModulesResponse {
    success: boolean
    data: {
        enabledModules: any
    }
}

@Injectable({ providedIn: 'root' })
export class NewAffiliateService {
    public newAffiliateInitialDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    public partnerModulesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    public newAffiliateMccData = new BehaviorSubject<any>(null)

    constructor(
        private httpClient: HttpClient,
        private httpClientOptionsService: HttpClientOptionsService,
    ) {}

    creteadAffiliate(Merchant: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/affiliate/created-affiliate`,
            Merchant,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    creteadAffiliatePSP(newAccount: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/affiliate-psp/new-account-psp/`,
            newAccount,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    creteadDraft(Merchant: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/affiliate/created-draft`,
            Merchant,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getRelatedPartnerModules() {
        return this.httpClient.get<GetRelatedPartnerModulesResponse>(
            `${environment.URL_BASE}/partnerhub/commercial-partner/related-partner-modules`,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getPartnerByPartnerId(partner_id: string) {
        return this.httpClient
            .post<any>(
                `${environment.URL_BASE}/partnerhub/commercial-partner/get-commercial-partners-data`,
                {
                    search_by_partner_id: partner_id,
                    registers_per_page: 1,
                    page_number: 1,
                },
                this.httpClientOptionsService.getPartnerhubAuthorization(),
            )
            .pipe(map(data => data.commercial_partners_data[0]))
    }

    setPix(document: any, fee_amount: any, partner_id: any, stone_code: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/affiliate/set-pix-fee`,
            {
                document,
                fee_amount,
                partner_id,
                stone_code,
            },
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getInitCreatedAffiliate(draftId: any, affiliateApprovalPriceId: any) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/affiliate/get-init-affiliate/${draftId}/${affiliateApprovalPriceId}`,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getApplications(partnerId: any) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/application-management/query-application/${partnerId}/0`,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getInitAddInformation(partnerId: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/affiliate/get-init-add-information`,
            { partnerId },
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getPostalCode(postalCode: any) {
        return this.httpClient.get<any>(`https://viacep.com.br/ws/${postalCode}/json/`)
    }

    approvalAffiliate(body: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/pricing-affliated-approved/approved`,
            body,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    contralApprovalAffiliate(body: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/pricing-affliated-approved/contral-approved`,
            body,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    deleteAffiliatePrice(body: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/pricing-affliated-approved/delete`,
            body,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    rejectAffiliatePrice(body: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/pricing-affliated-approved/reject`,
            body,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getPricePolicyBizdevSelect(partnerId: any) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/affiliate/get-price-policy-bizdev-select/${partnerId}`,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getMccListByCpnj(cnpj: string) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/marcopolo-get-cnpj/get-cnae/${cnpj}`,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    createMerchant(merchantData: MerchantRequest) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/affiliate-psp/create-merchant`,
            merchantData,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getMerchants(filterObject: any) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/affiliate-psp/get-merchants`,
            this.httpClientOptionsService.getPartnerhubAuthorizationAndParams(filterObject),
        )
    }

    getAccounts(filterObject: any) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/affiliate-psp/reports/merchants/${filterObject.merchant_id}/accounts`,
            this.httpClientOptionsService.getPartnerhubAuthorizationAndParams(filterObject),
        )
    }

    createAccount(accountData: AccountRequest) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/affiliate-psp/new-account-psp`,
            accountData,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }
}
